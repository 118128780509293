<template>
    <fragment>
        <v-popover
            auto-hide="false"
            trigger="hover"
            placement="left"
            v-if="isCreateDisabled"
            class="ml-auto"
        >
            <a class="btn btn-sm btn-alt-success px-15 my-5 mr-15 ml-auto" href="javascript:void(0);">
                <i class="fa fa-plus mr-5"></i>
                Добавить сотрудника
            </a>
            <div slot="popover" style="padding: 0 10px;">
                <b>Внимание</b>
                <div>
                    Достигнут лимит по числу пользователей, пожалуйста,
                    перейдите на другой тарифный план
                </div>
            </div>
        </v-popover>

        <a v-else class="add-staff" @click="openModal" href="javascript:void(0);">
            <i class="fa fa-plus mr-5"></i>
            Добавить сотрудника
        </a>
    </fragment>
</template>

<script>
    import session from '@/api/session'
    import { mapGetters, mapState } from "vuex"

    export default {
        name: 'AddStructureButton',
        data() {
            return {
                isCreateDisabled: false
            }
        },
        computed: {
            ...mapGetters('default_data', ['isBlocked'])
        },
        methods: {
            openModal() {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const ChangeStaffModal = () => import(`../ChangeStaffModal`)
                    this.$modal.show(
                        ChangeStaffModal,
                        {},
                        {
                            name: 'change-staff-modal',
                            scrollable: true,
                            height: 'auto',
                            adaptive: true,
                            maxWidth: 740,
                            width: '100%',
                            pivotY: 0,
                            clickToClose: false
                        }
                    )
                }
            }
        },
        async mounted() {
            let usersCount,
                current_rate;

            await Promise.all([
                session.get(`/api/v1/users/count/`),
                session.get(`/api/v1/rate/current_rate/`)
            ])
            .then(([res1, res2]) => {
                const data1 = res1.data;
                const data2 = res2.data;

                usersCount = data1.count;
                current_rate = data2;

                if (
                    usersCount >= 5 &&
                    current_rate.is_free_rate &&
                    !current_rate.exclude_domain
                ) {
                    this.isCreateDisabled = true;
                }
            })
            .catch((error) => {
                console.error(error);
            })
        }
    }
</script>

<style lang="scss" scoped>
    .add-staff {
        border-radius: 50px;
        padding: 6px 22px;
        color: #000 !important;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        border: 2px solid #fbcf56;
        cursor: pointer;
        transition: background-color 200ms ease;

        &:hover {
            background-color: #fbcf56;
        }
    }
</style>
